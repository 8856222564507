import neocIMG from './neoc.webp'
import flourishexelIMG from './flourishexel.webp'
import betafixIMG from './betafix.webp'
import melafixIMG from './melafix.webp'
import pimafixIMG from './pimafix.webp'
import bioExitGreenIMG from './bio-exit-green.webp'
import neosolution1IMG from './neosolution1.webp'
import neosolutionKIMG from './neosolutionK.webp'
import neosolutionFeIMG from './neosolutionFe.webp'
import guramiIMG from './gurami.webp'
import inbokIMG from './inbok.webp'
import StressShield from './StressShield.webp'
import CyanoShield from './CyanoShield.webp'
import NitrateRemover from './NitrateRemover.webp'
import EasyBacteriaStep0 from './EasyBacteriaStep0.webp'
import NeoBooster from './NeoBooster.webp'
import NeoSolution2 from './NeoSolution2.webp'
import Contest from './Contest.png'

const images = {
    Contest,
    neocIMG,
    flourishexelIMG,
    betafixIMG,
    melafixIMG,
    pimafixIMG,
    bioExitGreenIMG,
    neosolution1IMG,
    neosolutionKIMG,
    neosolutionFeIMG,
    guramiIMG,
    inbokIMG,
    StressShield,
    CyanoShield,
    NitrateRemover,
    EasyBacteriaStep0,
    NeoBooster,
    NeoSolution2
}
export default images