import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import React from 'react';

export const FHTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: '0.9rem',
        padding: '4px 8px',
        marginBottom: '14px !important',
        fontWeight: 500,
        lineHeight: '1.14286em',
    },
}));

