import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Calculator from './pages/calculator/Calculator2';
import News from './pages/news/News';
import { NotFound } from './pages/information/';
import styles from './pages/Main.module.scss';
import BottomNav from './pages/components/BottomNav'
function AppRouter() {
    return (
        <div className={styles.main}>
            <Router>
                <Routes>
                    <Route path='/' element={<Calculator />} />
                    <Route path='/calc' element={<Calculator />} />
                    <Route path='/news' element={<News />} />
                    <Route path="/*" element={<NotFound />} />
                </Routes>
                <BottomNav />
            </Router>

        </div >
    );
}

export default AppRouter;