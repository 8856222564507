const baseUrl =
    'https://objectstorage.ap-chuncheon-1.oraclecloud.com/p/ofPOF2HH9UIWYOOOWFSN8JM6QxKtAeHBTtqVJsZLMCq8H82CHS5i6-HjfDyO1Zd8/n/ax8vztmm9ho3/b/bucket-20240905-2203/o/productImg/';
const images = {
    neocIMG: baseUrl + 'neoC.webp',
    neoaIMG: baseUrl + 'neoA.webp',
    neovIMG: baseUrl + 'neoV.webp',
    neosolution1IMG: baseUrl + 'neoSolution1.webp',
    easyBacteriaStep0IMG: baseUrl + 'easyBacteriaStep0.webp',
    nitrateRemoverIMG: baseUrl + 'nitrateRemover.webp',
};
export default images;
