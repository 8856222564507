import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';

const FishTank = ({ width, height, depth, tankSand, waterLevel, isAnimating }) => {
    const fishTankRef = useRef();
    const modelRef = useRef();

    useFrame(() => {
        if (fishTankRef.current && isAnimating) {
            fishTankRef.current.rotation.y += 0.01;
        }
    });

    useEffect(() => {
        if (fishTankRef.current) {
            const fishTank = fishTankRef.current;
            fishTank.clear();

            const geometry = new THREE.BoxGeometry(width, height, depth);
            const material = new THREE.MeshStandardMaterial({
                color: new THREE.Color('rgb(18, 129, 159)'),
                transparent: true,
                opacity: 0.3,
                side: THREE.DoubleSide,
                depthWrite: false,
            });
            const fishTankMesh = new THREE.Mesh(geometry, material);
            fishTankMesh.renderOrder = 4
            fishTank.add(fishTankMesh);

            const edges = new THREE.EdgesGeometry(geometry);
            const lineMaterial = new THREE.LineBasicMaterial({ color: 0x76899d });
            const wireframe = new THREE.LineSegments(edges, lineMaterial);
            fishTank.add(wireframe);

            const soilGeometry = new THREE.BoxGeometry(width * 0.96, tankSand * 0.96, depth * 0.96);
            const soilMaterial = new THREE.MeshStandardMaterial({
                color: new THREE.Color('rgb(150, 96, 29)'),
                transparent: true,
                opacity: 0.714,
                depthWrite: false,
            });
            const soil = new THREE.Mesh(soilGeometry, soilMaterial);
            soil.renderOrder = 5; // 렌더 순서를 먼저 설정
            soil.position.y = -(height / 2) + tankSand / 2;
            if (tankSand > 0 && height - waterLevel >= tankSand) {
                fishTank.add(soil);
            }


            const waterGeometry = new THREE.BoxGeometry(width * 0.96, (height - tankSand - waterLevel) * 0.96, depth * 0.96);
            const waterMaterial = new THREE.MeshStandardMaterial({
                color: new THREE.Color('rgb(18, 129, 159)'),
                transparent: true,
                opacity: 0.5,
                depthWrite: false,
            });
            const water = new THREE.Mesh(waterGeometry, waterMaterial);
            water.position.y = -(height / 2) + tankSand + (height - tankSand - waterLevel) / 2;
            water.renderOrder = 2;
            if (height - tankSand - waterLevel > 0) {
                fishTank.add(water);
            }
            if (height - tankSand - waterLevel > 5) {
                // GLTF 모델 로드
                const loader = new GLTFLoader();
                loader.load(
                    '/models/betta_splendens/scene.gltf', // GLTF 모델 파일 경로
                    (gltf) => {
                        const model = gltf.scene;
                        modelRef.current = model;
                        model.position.set(0, 0, 0); // 모델 위치 조정
                        model.scale.set(1, 1, 1); // 모델 크기 조정
                        model.rotation.y = Math.PI / 2;
                        model.renderOrder = 0;
                        water.add(model);
                    },
                    undefined,
                    (error) => {
                        console.error('An error occurred while loading the GLTF model:', error);
                    }
                );
            }



        }
    }, [width, height, depth, tankSand, waterLevel]);

    return <group ref={fishTankRef} />;
};

const CameraControls = ({ width, height, depth, setIsAnimating }) => {
    const { camera, gl } = useThree();
    const controls = useRef();
    const [isReturning, setIsReturning] = useState(false);
    const [initialCameraPosition, setInitialCameraPosition] = useState(new THREE.Vector3());
    const returnSpeed = 0.05; // 카메라가 초기 위치로 돌아가는 속도 조절

    useEffect(() => {
        const maxSize = Math.max(width, height, depth);
        const minSize = Math.min(width, height, depth);
        const size = maxSize <= 30 ? maxSize * 3 : maxSize * 2;

        controls.current = new OrbitControls(camera, gl.domElement);
        controls.current.enablePan = false;
        controls.current.enableDamping = true;
        controls.current.dampingFactor = 0.2;
        controls.current.minDistance = minSize; // 카메라의 최소 거리
        controls.current.maxDistance = 300; // 카메라의 최대 거리

        camera.position.z = size

        const handleStart = () => {
            setIsAnimating(false);
            setIsReturning(false);
            // 현재 카메라 위치 저장
            setInitialCameraPosition(camera.position.clone());
        };

        const handleEnd = () => {
            setIsAnimating(true);
            setIsReturning(true);
        };

        controls.current.addEventListener('start', handleStart);
        controls.current.addEventListener('end', handleEnd);

        return () => {
            controls.current.removeEventListener('start', handleStart);
            controls.current.removeEventListener('end', handleEnd);
            controls.current.dispose();
        };
    }, [camera, gl, width, height, depth, setIsAnimating]);

    useFrame(() => {
        if (controls.current) controls.current.update();
        if (isReturning) {
            const dy = (0 - camera.position.y) * returnSpeed;
            const dz = (initialCameraPosition.z - camera.position.z) * returnSpeed;

            camera.position.y += dy;
            camera.position.z += dz;

            if (
                Math.abs(0 - camera.position.y) < 0.1 &&
                Math.abs(initialCameraPosition.z - camera.position.z) < 0.1
            ) {
                camera.position.y = 0
                camera.position.z = initialCameraPosition.z
                setIsReturning(false);
            }
        }
    });

    return null;
};

const App = ({ inputWidth, inputHeight, inputDepth, inputTankSand, inputWaterLevel }) => {
    const [isAnimating, setIsAnimating] = useState(true);

    return (
        <div style={{ width: '300px', height: '300px' }}>
            <Canvas
                camera={{
                    position: [0, 0, 150], // 초기 카메라 위치
                    fov: 45, // 시야각
                    near: 0.1, // 카메라가 렌더링할 수 있는 가장 가까운 거리
                    far: 1000, // 카메라가 렌더링할 수 있는 가장 먼 거리
                }}
            >
                <ambientLight intensity={2.5} />
                <FishTank
                    width={inputWidth}
                    height={inputHeight}
                    depth={inputDepth}
                    tankSand={inputTankSand}
                    waterLevel={inputWaterLevel}
                    isAnimating={isAnimating}
                />
                <CameraControls
                    width={inputWidth}
                    height={inputHeight}
                    depth={inputDepth}
                    setIsAnimating={setIsAnimating} />
            </Canvas>
        </div>
    );
};

export default App;
