import React, {useEffect, useRef, useState} from 'react';
import {Typography, Container, Card, CardContent, Grid, Box, CardHeader, Skeleton, IconButton, Snackbar} from '@mui/material';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {Helmet} from 'react-helmet';
import styles from '../calculator/index.module.scss';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IosShareIcon from '@mui/icons-material/IosShare';
import Avatar from '@mui/material/Avatar';
import uuid from 'react-uuid';
import {useSnackbar} from '../../contexts/SnackbarContext';

import {styled} from '@mui/material/styles';
import {useInfiniteQuery} from '@tanstack/react-query';
import {RssFeed} from '@mui/icons-material';
import useShareOrCopyLink from '../../hooks/useShareOrCopyLink';

import {
    collection,
    query,
    orderBy,
    startAt,
    limit,
    getDocs,
    addDoc,
    startAfter,
    QueryDocumentSnapshot,
    DocumentData,
    where,
    doc,
    getDoc,
} from 'firebase/firestore';

import database from '../../firebase/config';

interface RssItem {
    id: string;
    title: string;
    author: {
        id: string;
        name: string;
        avatar: string;
    };
    link: string;
    date: string;
    summary: string;
}

interface FetchRssFeedResult {
    items: RssItem[];
    nextPage: string | null;
}
const fetchUserNickname = async () => {
    const docRef = doc(database, 'news_feed_users', 'naver');
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    if (docSnap.exists() && data?.nicknameMap) {
        return data.nicknameMap;
    } else {
        return {};
    }
};
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const fetchRssFeed = async ({pageParam}: {pageParam: QueryDocumentSnapshot<DocumentData> | null}): Promise<FetchRssFeedResult> => {
    const itemsPerPage = 8;
    let rssFeedQuery;

    if (pageParam) {
        await new Promise((resolve) => setTimeout(resolve, 300));
        // 이전 페이지의 마지막 문서 스냅샷을 기준으로 다음 페이지 가져옴
        rssFeedQuery = query(
            collection(database, 'news_feed'),
            where('is_published', '==', true),
            orderBy('date', 'desc'),
            orderBy('__name__'),
            startAfter(pageParam),
            limit(itemsPerPage + 1)
        );
    } else {
        // 첫 페이지 가져옴
        rssFeedQuery = query(
            collection(database, 'news_feed'),
            where('is_published', '==', true),
            orderBy('date', 'desc'),
            orderBy('__name__'),
            limit(itemsPerPage + 1)
        );
    }
    const querySnapshot = await getDocs(rssFeedQuery);
    const items: RssItem[] = [];
    let lastVisible: QueryDocumentSnapshot<DocumentData> | null = null;

    querySnapshot.docs.forEach((doc, index) => {
        if (index < itemsPerPage) {
            const item = doc.data() as RssItem;
            item.id = doc.id;
            items.push(item);
        }
        if (index === itemsPerPage) {
            lastVisible = doc;
        }
    });

    return {
        items,
        nextPage: lastVisible || null, // 다음 페이지를 위한 문서 스냅샷
    };
};

const RotatingIcon = styled(AutorenewIcon)({
    animation: 'spin 1s linear infinite',
    color: 'gray',
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
});

const RessFeedItem: React.FC<{item?: any; userNicknameMap: Map<string, string>; isLoading?: boolean}> = ({item, userNicknameMap, isLoading}) => {
    const userNickname = item ? userNicknameMap.get(item.author.id) || item.author.id : '';
    return (
        <StyledCard
            key={item ? item.id : uuid()}
            sx={{cursor: 'pointer'}}
            onClick={() => {
                window.location.href = `${item.link}`;
            }}>
            <CardHeader
                title={
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                fontFamily: 'goorm-sans-bold, "Roboto", "Helvetica", "Arial", sans-serif',
                                fontWeight: 700,
                                color: '#1976d2',
                            }}>
                            {!isLoading && item ? item.title : <Skeleton />}
                        </Typography>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                            {!isLoading && item ? (
                                <CustomAvatar src={item.author.avatar} alt={item.author.name}>
                                    {item.author.name.charAt(0)}
                                </CustomAvatar>
                            ) : (
                                <>
                                    <Skeleton variant="circular" width={24} height={24} />
                                </>
                            )}
                            {!isLoading && item ? (
                                <>
                                    <Typography variant="body2" color="text.secondary">
                                        {userNickname}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        •
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {new Date(item.date.toDate()).toLocaleDateString('ko-KR', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })}
                                    </Typography>
                                </>
                            ) : (
                                <Skeleton variant="text" width={'100%'} sx={{fontSize: '1rem'}} />
                            )}
                        </Box>
                    </Box>
                }
                sx={{paddingBottom: 1}}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {!isLoading && item ? (
                        item.summary
                    ) : (
                        <>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </>
                    )}
                </Typography>
            </CardContent>
        </StyledCard>
    );
};

const StyledCard = styled(Card)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',
    gap: theme.spacing(1),
    boxShadow: theme.shadows[4],
    transition: 'all 0.2s ease-in-out',
}));

const CustomAvatar = styled(Avatar)(({theme}) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    background: 'linear-gradient(45deg, #FAD0C4 30%, #FFD1FF 90%)',
}));

const StyledCardHeader = styled(CardHeader)(({theme}) => ({
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: theme.spacing(1),
    position: 'sticky',
    top: 0,
    zIndex: 1,
}));

const News: React.FC = () => {
    const {shareOrCopyLink} = useShareOrCopyLink();

    const [userNicknameMap, setUserNicknameMap] = useState<Map<string, string>>(new Map());
    const loadMoreRef = useRef(null);
    const {data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage} = useInfiniteQuery({
        queryKey: ['rss_feed'],
        queryFn: ({pageParam}: {pageParam: any}) => fetchRssFeed({pageParam}),
        initialPageParam: null,
        getNextPageParam: (lastPage) => lastPage.nextPage || null,
    });

    useEffect(() => {
        const setUserNickname = async () => {
            const userNickname = await fetchUserNickname();
            const userNicknameMap: Map<string, string> = new Map(Object.entries(userNickname));
            setUserNicknameMap(userNicknameMap);
        };
        setUserNickname();
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasNextPage) {
                    fetchNextPage();
                }
            },
            {threshold: 1}
        );

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            if (loadMoreRef.current) {
                observer.unobserve(loadMoreRef.current);
            }
        };
    }, [fetchNextPage, hasNextPage]);
    return (
        <>
            <Helmet>
                <title>피쉬하이 - 물생활 소식</title>
            </Helmet>

            <div className={styles['calcBody']}>
                <h1 className={styles['title']}>fishhi.kr</h1>
                <StyledCardHeader
                    sx={{m: 0.4}}
                    title={
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography
                                variant="h4"
                                component="div"
                                sx={{
                                    height: '40px',
                                    lineHeight: '40px',
                                    verticalAlign: 'middle',
                                    fontSize: {
                                        xs: '1.2rem', // 모바일 사이즈
                                    },
                                    fontFamily: 'goorm-sans-bold, "Roboto", "Helvetica", "Arial", sans-serif',
                                    fontWeight: 700,
                                    color: 'black',
                                }}>
                                피쉬하이::물생활 소식
                            </Typography>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    const url = 'https://fishhi.kr/news';
                                    shareOrCopyLink(url);
                                }}
                                aria-label="share this page">
                                <IosShareIcon />
                            </IconButton>
                        </div>
                    }
                />
                <Container component="main" style={{flex: 1, padding: '0 0 16px 0'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card variant="outlined" sx={{m: 0.4}}>
                                <CardContent>
                                    <StyledCard key={uuid()}>
                                        <img
                                            width={'100%'}
                                            src="https://objectstorage.ap-chuncheon-1.oraclecloud.com/p/vQvar1y8OI7QWW4M5HCPrXTvsQIRggCqbtu8Zm_e9SbaFYyrFUTwQevLw1we9dsl/n/ax8vztmm9ho3/b/bucket-20240905-2203/o/banner/feed-banner3.webp"
                                        />
                                    </StyledCard>

                                    {isLoading
                                        ? Array.from(new Array(5)).map((_, index) => (
                                              <RessFeedItem key={index} userNicknameMap={userNicknameMap} isLoading />
                                          ))
                                        : data?.pages.map((page, pageIndex) => (
                                              <React.Fragment key={pageIndex}>
                                                  {page.items.map((item) => (
                                                      <RessFeedItem item={item} userNicknameMap={userNicknameMap} isLoading={isLoading} />
                                                  ))}
                                              </React.Fragment>
                                          ))}
                                    <div></div>
                                    <Box
                                        sx={{display: 'flex', justifyContent: 'center', color: 'text.secondary', padding: '5px 0'}}
                                        ref={loadMoreRef}>
                                        {hasNextPage && isFetchingNextPage ? <RotatingIcon /> : !isLoading ? '마지막 페이지 입니다.' : ''}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {/* </div> */}
        </>
    );
};

export default News;