import AppRouter from './AppRouter';
import CombinedProvider from './contexts/CombinedProvider';

function App() {
    return (
        <CombinedProvider>
            <AppRouter />
        </CombinedProvider>
    );
}

export default App;
