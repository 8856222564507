import {useCallback} from 'react';
import {useSnackbar} from '../contexts/SnackbarContext';

const useShareOrCopyLink = () => {
    const {showSnackbar} = useSnackbar();
    const shareOrCopyLink = useCallback((url: string) => {
        // mobile기기체크
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) && navigator.share) {
            navigator
                .share({
                    title: document.title,
                    url: url,
                })
                .then(() => showSnackbar('링크가 공유되었습니다!'))
                .catch((err) => console.error('링크 공유 실패', err));
        } else {
            if (typeof navigator.clipboard === 'undefined') {
                const textArea = document.createElement('textarea');
                textArea.value = url;
                textArea.style.position = 'fixed';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    const successful = document.execCommand('copy');
                    if (successful) {
                        showSnackbar('링크가 복사되었습니다.');
                    }
                } catch (err) {
                    console.error('링크 복사 실패', err);
                }

                document.body.removeChild(textArea);
            } else {
                navigator.clipboard
                    .writeText(url)
                    .then(() => showSnackbar('링크가 복사되었습니다.'))
                    .catch((err) => console.error('링크 복사 실패', err));
            }
        }
    }, []);

    return {shareOrCopyLink}; // 함수를 반환
};

export default useShareOrCopyLink;
