import React, {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Paper, Box} from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CalculateIcon from '@mui/icons-material/Calculate';
import NewspaperIcon from '@mui/icons-material/Newspaper';

function BottomNav() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setValue(pathToValue(location.pathname));
    }, [location.pathname]);

    const pathToValue = (path: string) => {
        switch (path) {
            case '/calc':
                return 0;
            case '/news':
                return 1;
            case '/archive':
                return 2;
            default:
                return 0;
        }
    };

    const [value, setValue] = React.useState(pathToValue(location.pathname));

    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
        setValue(newValue);
        if (newValue === 0) {
            navigate('/calc');
        } else if (newValue === 1) {
            navigate('/news');
        } else if (newValue === 2) {
            navigate('/archive');
        }
    };
    const paddingBottomValue = isIOSPWA() ? '15px' : '0px';
    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                paddingBottom: {
                    xs: paddingBottomValue,
                },
            }}
            elevation={3}>
            <BottomNavigation showLabels value={value} onChange={handleChange}>
                <BottomNavigationAction label="계산기" icon={<CalculateIcon />} />
                <BottomNavigationAction label="소식" icon={<NewspaperIcon />} />
                {/* <BottomNavigationAction label="Archive" icon={<ArchiveIcon />} /> */}
            </BottomNavigation>
        </Paper>
    );
}

declare global {
    interface Window {
        MSStream: any;
    }
    interface Navigator {
        standalone?: boolean;
    }
}

const isIOSPWA = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isInStandaloneMode = window.navigator.standalone === true;
    return isIOS && isInStandaloneMode;
};

export default BottomNav;
