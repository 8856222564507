import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Divider, IconButton, Autocomplete, TextField, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, AppBar, Toolbar, Slide, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState, forwardRef, ReactElement, Ref, Suspense } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from '../index.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { ILiquidItem } from '../TabComponent'
import { maxWidth } from '@mui/system';
const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean,
    selectedItem: ILiquidItem,
    handleClose: () => void
}


const ProductDialog = (props: IProps) => {
    const initializeLoading = { productImage: true };
    const useFoolScreen = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(initializeLoading);

    useEffect(() => {
        setLoading({ productImage: props.open });
    }, [props.open])

    return (
        <Dialog
            fullScreen={useFoolScreen}
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}
            sx={{
                width: '100%',
                left: '50%',
                transform: 'translate(-50%)',
            }}
        >
            <AppBar sx={{ position: 'sticky', top: 0 }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {props.selectedItem.itemName}
                    </Typography>
                    {/* <Button autoFocus color="inherit" onClick={props.handleClose}>
                        최저가 검색
                    </Button> */}
                </Toolbar>
            </AppBar>
            <div style={{ maxWidth: '100%', width: '100vw', height: '100vh', boxSizing: 'border-box' }}>
                <img src={props.selectedItem.productImage} width={'100%'} onLoad={() => setLoading({ ...loading, productImage: false })} />
                {loading.productImage && <>
                    <div style={{ width: '100%', height: '20%', display: 'flex', justifyContent: 'space-between', padding: '10px', boxSizing: 'border-box' }}>
                        <Skeleton animation="wave" variant="rectangular" width="calc(50% - 5px)" height={'100%'} />
                        <div style={{ width: 'calc(50% - 5px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Skeleton variant="rounded" animation="wave" width={'50%'} />
                            <Skeleton variant="rounded" animation="wave" />
                            <Skeleton variant="rounded" animation="wave" />
                            <Skeleton variant="rounded" animation="wave" height={50} />
                        </div>
                    </div>
                    <div style={{ width: "100%", height: 'calc(80%)', padding: '10px', boxSizing: 'border-box' }}>
                        <Skeleton animation="wave" variant="rectangular" width="100%" height={'100%'} />
                    </div>
                </>
                }
            </div>
            <Divider />
        </Dialog >
    );
}


export default ProductDialog;