import React, {useState, useEffect} from 'react';
import {Snackbar, AlertColor} from '@mui/material';
import MuiAlert, {AlertProps} from '@mui/material/Alert';

interface CustomSnackbarProps {
    open: boolean;
    handleOnClose: () => void;
    message: string;
    duration?: number;
    severity?: AlertColor; // "error" | "warning" | "info" | "success"
}

const checkIOSPWA = (): boolean => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isInStandaloneMode = (window.navigator as any).standalone === true;
    return isIOS && isInStandaloneMode;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const HiSnackbar: React.FC<CustomSnackbarProps> = ({open, handleOnClose, message, duration = 2000, severity = 'success'}) => {
    const isIOSPWA: boolean = checkIOSPWA();
    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={duration}
                onClose={handleOnClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                sx={{marginBottom: isIOSPWA ? '70px' : '40px'}}>
                <Alert
                    onClose={handleOnClose}
                    severity={severity}
                    sx={{width: '100%'}}
                    style={{backgroundColor: '#88BFCF', fontWeight: 'bold', color: 'white'}}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default HiSnackbar;
