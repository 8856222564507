import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Corydoras from './imges/corydoras-01.png';

const NotFound: React.FC = () => {
    const [countDown, setCountDown] = useState<number>(3);
    const navigate = useNavigate();
    useEffect(() => {
        const countDownInterval = setInterval(() => {
            setCountDown((countDown) => countDown - 1);
        }, 1000);
        return () => clearTimeout(countDownInterval);
    }, []);

    useEffect(() => {
        if (countDown <= 0) {
            navigate('/');
        }
    }, [countDown, navigate]);

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '16pt', fontWeight: 'bold', color: '#76899d' }}>
                <img src={Corydoras} alt="not found" style={{ width: '40vw', height: '40vw' }} />

                <p style={{ textAlign: 'center' }}>요청하신 페이지를 찾을 수 없습니다.</p>
                <p style={{ textAlign: 'center' }}>{countDown.toString()}초뒤 HOME화면으로 이동합니다.</p>
            </div>
        </div>
    );
};

export default NotFound;