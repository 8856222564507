import { Box, Tabs, Tab, Typography, } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import Liquid from './Liquid';
import Flooring from './Flooring';
import Favorites from './Favorites';
import images from './images';
import productImages from './images/productIMG';
import { Favorite } from '@mui/icons-material';

interface TabComponentProps {
    capacity: Number;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface ILiquidItem {
    sq: number;
    type: string;
    typeName: string;
    favorite: boolean;
    itemName: string;
    itemDescription: string;
    itemSearchText: string[];
    recommendedDosage1: string;
    recommendedDosage2: string;
    perLiterFineCapacity: number;
    perLiterFineCapacityUnit: string;
    image: any;
    productImage: any;

}

export interface IFavorite {
    sq: number;
    type: string;
}
export interface IFavoritesList extends Array<IFavorite> { }


export interface ILiquidItemList extends Array<ILiquidItem> { }


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 4 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const TabComponent = (props: TabComponentProps) => {
    const itemLists = [
        { sq: 0, type: 'liquid', typeName: '약품', favorite: false, itemName: '네오C', itemDescription: '', itemSearchText: ['neoC'], recommendedDosage1: '40L', recommendedDosage2: '10cc', perLiterFineCapacity: 0.25, perLiterFineCapacityUnit: 'cc', image: images.neocIMG, productImage: productImages.neocIMG },
        { sq: 1, type: 'liquid', typeName: '약품', favorite: false, itemName: '네오A', itemDescription: '', itemSearchText: [''], recommendedDosage1: '40L', recommendedDosage2: '10cc', perLiterFineCapacity: 0.25, perLiterFineCapacityUnit: 'cc', image: images.neocIMG, productImage: productImages.neoaIMG },
        { sq: 2, type: 'liquid', typeName: '약품', favorite: false, itemName: '네오V 블랙', itemDescription: '', itemSearchText: [''], recommendedDosage1: '40L', recommendedDosage2: '10cc', perLiterFineCapacity: 0.25, perLiterFineCapacityUnit: 'cc', image: images.neocIMG, productImage: productImages.neovIMG },
        { sq: 3, type: 'liquid', typeName: '약품', favorite: false, itemName: '플로엑셀', itemDescription: '', itemSearchText: [''], recommendedDosage1: '40L', recommendedDosage2: '5cc', perLiterFineCapacity: 0.125, perLiterFineCapacityUnit: 'cc', image: images.flourishexelIMG, productImage: '' },
        { sq: 4, type: 'liquid', typeName: '약품', favorite: false, itemName: '멜라픽스', itemDescription: '', itemSearchText: [''], recommendedDosage1: '38L', recommendedDosage2: '5cc', perLiterFineCapacity: 0.1315789474, perLiterFineCapacityUnit: 'cc', image: images.melafixIMG, productImage: '' },
        { sq: 5, type: 'liquid', typeName: '약품', favorite: false, itemName: '피마픽스', itemDescription: '', itemSearchText: [''], recommendedDosage1: '38L', recommendedDosage2: '5cc', perLiterFineCapacity: 0.1315789474, perLiterFineCapacityUnit: 'cc', image: images.pimafixIMG, productImage: '' },
        { sq: 6, type: 'liquid', typeName: '약품', favorite: false, itemName: '베타픽스', itemDescription: '', itemSearchText: [''], recommendedDosage1: '3.8L', recommendedDosage2: '2.5cc', perLiterFineCapacity: 0.6578947368, perLiterFineCapacityUnit: 'cc', image: images.betafixIMG, productImage: '' },
        { sq: 7, type: 'liquid', typeName: '약품', favorite: false, itemName: '네오솔루션1', itemDescription: '3일에 한 번 10L에 1cc, 환수하는 경우 2배를 사용하세요. 사용량은 수초의 상태에 따라 증감하세요.', itemSearchText: [''], recommendedDosage1: '10L', recommendedDosage2: '1cc', perLiterFineCapacity: 0.1, perLiterFineCapacityUnit: 'cc', image: images.neosolution1IMG, productImage: productImages.neosolution1IMG },
        { sq: 8, type: 'liquid', typeName: '약품', favorite: false, itemName: '네오솔루션2', itemDescription: '3일에 한 번 10L에 1cc, 환수하는 경우 2배를 사용하세요. 사용량은 수초의 상태에 따라 증감하세요.', itemSearchText: [''], recommendedDosage1: '10L', recommendedDosage2: '1cc', perLiterFineCapacity: 0.1, perLiterFineCapacityUnit: 'cc', image: images.NeoSolution2, productImage: productImages.neosolution1IMG },
        { sq: 9, type: 'liquid', typeName: '약품', favorite: false, itemName: '네오솔루션K', itemDescription: '3일에 한 번 10L에 1cc, 환수하는 경우 2배를 사용하세요. 사용량은 수초의 상태에 따라 증감하세요.', itemSearchText: [''], recommendedDosage1: '10L', recommendedDosage2: '1cc', perLiterFineCapacity: 0.1, perLiterFineCapacityUnit: 'cc', image: images.neosolutionKIMG, productImage: productImages.neosolution1IMG },
        { sq: 10, type: 'liquid', typeName: '약품', favorite: false, itemName: '네오솔루션Fe', itemDescription: '3일에 한 번 10L에 1cc, 환수하는 경우 2배를 사용하세요. 사용량은 수초의 상태에 따라 증감하세요.', itemSearchText: [''], recommendedDosage1: '10L', recommendedDosage2: '1cc', perLiterFineCapacity: 0.1, perLiterFineCapacityUnit: 'cc', image: images.neosolutionFeIMG, productImage: productImages.neosolution1IMG },
        { sq: 11, type: 'liquid', typeName: '약품', favorite: false, itemName: '바이오엑시트그린', itemDescription: '', itemSearchText: [''], recommendedDosage1: '40L', recommendedDosage2: '10cc', perLiterFineCapacity: 0.25, perLiterFineCapacityUnit: 'cc', image: images.bioExitGreenIMG, productImage: '' },
        { sq: 12, type: 'liquid', typeName: '약품', favorite: false, itemName: '네이처팜 스트레스 쉴드(염소제거+점막보호)', itemDescription: '', itemSearchText: [''], recommendedDosage1: '50L', recommendedDosage2: '15cc', perLiterFineCapacity: 0.3, perLiterFineCapacityUnit: 'cc', image: images.StressShield, productImage: '' },
        { sq: 13, type: 'liquid', typeName: '약품', favorite: false, itemName: '네이처팜 나이트레이트 리무버 (질산염 제거제)', itemDescription: '', itemSearchText: [''], recommendedDosage1: '100L', recommendedDosage2: '15cc', perLiterFineCapacity: 0.15, perLiterFineCapacityUnit: 'cc', image: images.NitrateRemover, productImage: productImages.nitrateRemoverIMG },
        { sq: 14, type: 'liquid', typeName: '약품', favorite: false, itemName: '네이처팜 이지 박테리아 스텝 0 (염소중화제)', itemDescription: '', itemSearchText: [''], recommendedDosage1: '100L', recommendedDosage2: '30cc', perLiterFineCapacity: 0.3, perLiterFineCapacityUnit: 'cc', image: images.EasyBacteriaStep0, productImage: productImages.easyBacteriaStep0IMG },
        { sq: 15, type: 'liquid', typeName: '약품', favorite: false, itemName: '네이처팜 시아노 쉴드 (시아노 제거제)', itemDescription: '', itemSearchText: [''], recommendedDosage1: '50L', recommendedDosage2: '15cc', perLiterFineCapacity: 0.3, perLiterFineCapacityUnit: 'cc', image: images.CyanoShield, productImage: '' },
        { sq: 16, type: 'liquid', typeName: '약품', favorite: false, itemName: '네오 부스터 플랜츠', itemDescription: '', itemSearchText: [''], recommendedDosage1: '40L', recommendedDosage2: '10cc', perLiterFineCapacity: 0.25, perLiterFineCapacityUnit: 'cc', image: images.NeoBooster, productImage: '' },
    ];
    const flooringItemLists = [
        { sq: 0, type: 'liquid', typeName: '약품', favorite: false, itemName: '네오C', itemDescription: '', itemSearchText: ['neoC'], recommendedDosage1: '40L', recommendedDosage2: '10cc', perLiterFineCapacity: 0.25, perLiterFineCapacityUnit: 'cc', image: images.neocIMG, productImage: '' },
    ];

    const [value, setValue] = useState(0);
    const [liquidItemList, setLiquidItemList] = useState<ILiquidItemList>(itemLists);
    const [flooringItemList, setFlooringItemList] = useState<ILiquidItemList>(flooringItemLists);
    const [favoriteLiquidItemList, setFavoriteLiquidItemList] = useState<ILiquidItemList>([]);
    const [favorites, setFavorites] = useState<IFavoritesList>([]);

    useEffect(() => {
        if (localStorage.getItem("liquidFavorites")) {
            const liquidFavorites: IFavoritesList = JSON.parse(localStorage.getItem("liquidFavorites") || '');

            const updatedLiquidItemList = liquidItemList.map(item => {
                const matchingFavorite = liquidFavorites.find(favorite => favorite.type === item.type && favorite.sq === item.sq);
                if (matchingFavorite) {
                    return { ...item, favorite: true };
                }
                return item;
            });
            setFavorites(liquidFavorites);
            setLiquidItemList(updatedLiquidItemList);
            setFavoriteLiquidItemList(updatedLiquidItemList.filter(item => item.favorite));
        }
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const setLiquidItemFavorite = (item: ILiquidItem) => {
        const targetItemIndex = liquidItemList.findIndex(target => target.sq === item.sq);
        let updatedTargetItemList = liquidItemList.map((selectedItem, index) => {
            if (index === targetItemIndex) {
                return { ...selectedItem, favorite: !selectedItem.favorite };
            }
            return selectedItem;
        });
        // updatedTargetItemList = updatedTargetItemList.sort((a, b) => {
        //     if (a.favorite && !b.favorite) {
        //         return -1;
        //     } else if (!a.favorite && b.favorite) {
        //         return 1;
        //     }
        //     return a.sq - b.sq;
        // });


        setLiquidItemList(updatedTargetItemList);
        setFavoriteLiquidItemList(updatedTargetItemList.filter(newItem => newItem.favorite));

        // const itemExists = favorites.some(prevItem => prevItem.type === item.type && prevItem.sq === item.sq);

        const itemIndex = favorites.findIndex(prevItem => prevItem.type === item.type && prevItem.sq === item.sq);
        if (itemIndex !== -1) {
            // 이미 항목이 존재하므로 제거
            const newFavorites = [...favorites];
            newFavorites.splice(itemIndex, 1);
            localStorage.setItem('liquidFavorites', JSON.stringify(newFavorites));
            setFavorites(newFavorites);
        } else {
            // 항목이 존재하지 않으므로 추가
            const newFavorites = [...favorites, { type: item.type, sq: item.sq }];
            localStorage.setItem('liquidFavorites', JSON.stringify(newFavorites));
            setFavorites(newFavorites);
        }

        // if (!itemExists) {
        // newItem을 배열에 추가, 추후 추가적인 아이템들을 관리하기위해 favorite 배열 별도로 보관
        // }

    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="약품(수질)" {...a11yProps(0)} style={{ fontFamily: 'Y_Spotlight' }} />
                        <Tab label="바닥재" {...a11yProps(1)} style={{ fontFamily: 'Y_Spotlight' }} disabled />
                        <Tab label="준비중" {...a11yProps(2)} style={{ fontFamily: 'Y_Spotlight' }} disabled />
                        <Tab label="즐겨찾기" {...a11yProps(3)} style={{ fontFamily: 'Y_Spotlight' }} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Liquid capacity={props.capacity} liquidItemList={liquidItemList} favorites={favorites} setLiquidItemFavorite={setLiquidItemFavorite} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Flooring capacity={props.capacity} liquidItemList={flooringItemList} favorites={favorites} setLiquidItemFavorite={setLiquidItemFavorite} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Favorites capacity={props.capacity} liquidItemList={favoriteLiquidItemList} favorites={favorites} setLiquidItemFavorite={setLiquidItemFavorite} />
                </TabPanel>
            </Box>
        </>
    );
};

export default TabComponent;